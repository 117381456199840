.header-container {
    background-color: #000000;
}

.nav-item .nav-link {
    color: #666;
}

.border-header {
    /* border: 1px solid #4a4a4a; */
    border-top: 1px solid #4a4a4a;
}


.popover {
    position: absolute;
    bottom: -64px;
    border-radius: 0%;
    margin-right: 13px;

}

.search-btn {
    background-color: #e96e4d;
    color: #fff;
    border-color: #e96e4d;
}

.form-control:focus {
    /* color: var(--bs-body-color); */
    /* background-color: var(--bs-body-bg); */
    /* border-color: #86b7fe; */
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.nav-item .nav-link.active {
    color: #e96e4d;
}

.nav-item .nav-link:hover {
    color: #e96e4d;
}

.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
    color: #e96e4d;
}

.btn-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    border-radius: 20%;
    background-color: #000000;
}