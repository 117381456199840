.footer-wrapper {
    padding: 30px 0;
    background-color: #1a1a1a;
    font-size: 14px;
    color: #666;
}

.footer-wrapper .copyright {
    float: left;
    color: #666;
    /* padding-top: 2px; */
    /* width: 65.64%; */
}

.footer-wrapper .copyright a {
    color: #666;
    text-decoration: none;
}

.footer-wrapper .icon-social , .icon-social, .icon-search{
    color: #666;
    font-size: 20px;
}

.icon-search:hover {
    color: #e96e4d;
    cursor: pointer;
}