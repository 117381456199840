@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&family=Rubik:wght@400;500&family=VT323&family=Zilla+Slab:wght@400;500&display=swap');


*{
  font-family: 'Zilla Slab', serif;
}
body {
  margin: 0;
  font-family: 'Zilla Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-body {
  height: 100vh;
}