.home-hero {
    background-image: url(/public//assets//img/hero-home.webp);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 260px;
    padding: 15% 0px;
    font: 16px 'Lato', sans-serif;
    font-family: 'Zilla Slab', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Zilla Slab', sans-serif;
}

.clearfix {
    display: block;
}

.container {
    margin: 0 auto;
    max-width: 1170px;
}

.featured-text,
.featured-text .featured-title a {
    color: #fff;
}

.featured-text .featured-title,
.featured-text .featured-content {
    padding-bottom: 40px;
}

.featured-text {
    padding: 0 5%;
    text-align: center;
    width: 90%;
}

.featured-text .featured-title {
    font-size: 60px;
    line-height: 1.3;
    font-weight: 300;
}

.featured-text .featured-content {
    font-size: 26px;
}

.featured-text .active {
    background-color: #e96e4d;
    border: none;
}

.featured-text .active:hover {
    background-color: #ffffff;
    color: #e96e4d;
}

.featured-text .call-to-action {
    padding: 12px 30px;
    color: #fff;
    border: 1px solid #fff;
    margin: 10px 10px 0;
}

.featured-text .call-to-action:hover {
    background-color: #fff;
    color: #e96e4d;
}




.call-to-action {

    color: #000;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 25px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #000;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}


/* About */
.widget_featured_page {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background-color: #fff;
    color: #000;
}

.widget_featured_page .containers {
    padding: 80px 0 60px;
}

.widget_featured_page .featured-page-content {
    /* width: 48.71%; */
    float: left;
}

.widget_featured_page .featured-page-content .widget-title {
    padding-bottom: 20px;
}

.widget_featured_page .featured-page-content .call-to-action:hover {
    background-color: #e96e4d;
    border-color: #e96e4d;
    color: #fff;
    /* transition: all 0.4s ease; */

}

p {
    padding-bottom: 20px;

    color: #666;
    font: 16px 'Lato', sans-serif;
    line-height: 1.5;
    word-wrap: break-word;
    font-weight: 300;
}

.widget_featured_page .featured-page-image {
    /* width: 46.15%; */
    float: right;
    padding-bottom: 20px;
}

widget_featured_page .featured-page-image figure {
    text-align: left;
}

.widget_featured_page .featured-page-image figure img {
    max-height: 400px;
}

/* About end */

/* Promotion  */
.widget_recent_work {
    margin-bottom: 0;
    padding-top: 60px;
    text-align: center;
    background-color: #fff;
    color: #000;
}

.container {
    margin: 0 auto;
    max-width: 1170px;
}

.widget_recent_work .recent-work-col {
    float: left;
    width: 33.33%;
    background-color: #000;
    position: relative;
}

.widget_recent_work .recent-work-col img:hover {

    opacity: .7;
    transition: all 0.8s ease;
}


.widget_recent_work .recent-work-col a {
    display: block;
    min-height: 150px;
}

.widget img {
    max-width: 100%;
}


img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

.widget_recent_work .recent-work-col a .recent-work-content {
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 90%;
    padding: 0 5% 5%;
    text-align: left;
}

span {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

.widget_recent_work .recent-work-col a .recent-work-title {
    font-size: 25px;
    font-weight: 400;
    line-height: 1.3;
    display: block;
    padding-bottom: 10px;
}

/* Promotion end */

/* Other */
.widget_service {
    margin-bottom: 0;
    padding: 60px 0 20px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background-color: #fff;
}

.container {
    margin: 0 auto;
    max-width: 1170px;
}

.column {
    margin-left: -2.56%;
}

.one-half,
.one-third,
.one-fourth {
    float: left;
    margin: 0 0 0 2.49%;
    padding-bottom: 30px;
}

.one-third {
    width: 30.83%;
}

.widget_service .large-view .service-img,
.widget_service .large-view .service-img img {
    width: auto;
    height: auto;
}

.widget_service .service-img {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
}

.widget_service .service-img,
.widget_service .service-img img {
    width: 70px;
    height: 70px;
}

#main h1,
#main h2,
#main h3,
#main h4,
#main h5,
#main h6 {
    padding-bottom: 20px;
}

.widget_service .service-title {
    font-weight: 400;
    font-size: 25px;
    display: block;
    overflow: hidden;
}

/* Other end */

/* Download */
.widget_promotional_bar {
    margin-bottom: 0;
    margin-top: -1px;

}


.promotional_bar_content,
.page-title-wrap {
    background-color: #e96e4d;
    color: #fff;
    background-attachment: fixed;
    background-color: #89a452;
    background-position: center center;
    background-size: cover;
    padding: 60px 0 40px;
    text-align: center;
    background-image: url("https://jiliko.cc/wp-content/uploads/2023/06/06-05-143314.png")
}


.container {
    margin: 0 auto;
    max-width: 1170px;
}

.widget_promotional_bar .widget-title,
#main .widget_promotional_bar .widget-title {
    color: #fff;
}

.widget_promotional_bar p {
    margin: 10px 0 20px;
    border-color: #fff;
    color: #fff;
    font-family: 'Zilla Slab', sans-serif;

}

.widget_promotional_bar .call-to-action {
    margin: 10px 0 20px;
    border-color: #fff;
    color: #fff;
    text-decoration: none;
}

.widget_promotional_bar .call-to-action:hover {
    background-color: #e96e4d;
    /* border: #e96e4d; */
    border-color: #e96e4d;
    text-decoration: none;
}

/* Download end */